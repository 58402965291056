import React from 'react'
import Layout from '../components/layout'

const URL = 'https://d26q9q5kxy2g52.cloudfront.net/gallery/static/bundle.js'

const NyhetsbrevPage = () => {
  return (
    <Layout articlelevel={100}>
      <>
        <div className="contentWrapper flex">
          <div className="newsletter-gallery-content">
            <div className="newsletter-gallery">
              <div
                id="gallery-root"
                data-public-path={URL}
                data-settings={JSON.stringify({
                  siteCode: 'lantmannen',
                })}
              ></div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}
export default NyhetsbrevPage
