import React from 'react'

const Footer = () => {
  return (
    <footer>
      <p>
        Ansvarig utgivare: <br />
        Lilian Almroth
        <br />
        010-184 43 79
        <br />
        lilian.almroth@lrfmedia.se
      </p>
      <ul className="footerNav">
        <li>
          <a href="https://www.lrfmedia.se/upphovsratt/">Upphovsrättsinfo</a>
        </li>
        <li>
          <a href="http://www.lrfmedia.se/cookies">Om cookies</a>
        </li>
        <li>
          <a href="http://www.lrfmedia.se/avtalsvillkor">Avtalsvillkor</a>
        </li>
        <li>
          <a href="http://www.lrfmedia.se/personuppgiftspolicy">
            Personuppgiftspolicy
          </a>
        </li>
        <li>
          <a href="javascript:Didomi.preferences.show()">Cookies</a>
        </li>
      </ul>
      <p>
        Utebliven tidning? Hjälp med Lantmannens e-tidning eller din
        prenumeration? Ring kundservice 010-184 40 84 eller mejla{' '}
        <a href="mailto:kundservice@lrfmedia.se">kundservice@lrfmedia.se</a>{' '}
        <br />
        Annonsera? <a href="https://ocast.com/se/lantmannen">Klicka här</a>{' '}
        <br />
        Vill du kontakta redaktionen? Ring 010-184 41 00 eller mejla{' '}
        <a href="mailto:red@lantmannen.nu">red@lantmannen.nu</a>
      </p>
      <p>
        Detta material är skyddat enligt lagen om upphovsrätt. Kopiering för
        annat än privat bruk är förbjudet. LRF Media AB Box 30133, 104 25
        Stockholm, Organisationsnummer: 556413-2883
        <br />© Lantmannen 2019
      </p>
    </footer>
  )
}

export default Footer
