import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../external/main.scss'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, articlelevel, actionBtn = true }) => {
  return (
    <StaticQuery
      level={articlelevel}
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content:
                  'Lantmannen är magasinet för alla lantbrukare som inte lämnar något åt slumpen. I tidningen möter du erfarna kollegor som lyfter sig lite extra.',
              },
              { name: 'keywords', content: 'Lantbruk, lantmannen, magasin' },
            ]}
          >
            <html lang="en" />
            <body className="Habitat" data-prop-articlelevel={articlelevel} />
          </Helmet>
          <Header
            siteTitle={data.site.siteMetadata.title}
            articleLevel={articlelevel}
          />

          <div id="main-content">
            {children}

            <div id="paywall-root" />
          </div>
          {actionBtn ? (
            <div className="actionBtn libre">
              <a href="/etidningen">Läs e-tidningen</a>
            </div>
          ) : (
            <div style={{ height: "100px" }}></div>
          )}

          <Footer />
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
